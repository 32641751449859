.firebaseui-container {
    max-width:100% !important;
    width:100% !important;
    height: 100% !important;
    max-height: 100% !important;
    background-color: rgb(100,100,100,0.8) !important;
}

#firebaseui-auth-container {
    height: 100% !important;
}

.firebaseui-card-content {
    margin-top: -24px !important;
    padding-top: 100px !important;
}

.firebaseui-card-content form {
    border-top: 1px solid #222;
    border-right: 1px solid #222;
    border-left: 1px solid #222;
    padding: 24px;
    background-color:#c7daec;
    box-shadow: 0px 10px 20px rgb(0 0 0 / 50%);
    border-radius: 8px 8px 0px 0px;
}

.firebaseui-card-footer p {
    background-color: rgb(240, 248, 255);
    box-shadow: 0px 15px 20px rgb(0 0 0 / 50%);
    border-radius: 0px 0px 8px 8px;
    border-right: 1px solid #222;
    border-left: 1px solid #222;
    border-bottom: 1px solid #222;
}

.sim-align-bottom {
    position: absolute;
    bottom: 0;
    left: 0%;
    width: 100%;
}

.captured_pic_preview_on_modal {
    width: 320px;
}

.modal-punch-frame, .modal-punch-frame .ion-page {
    padding:12px;
    justify-content: inherit;
}

.modal-punch-frame ion-header, .modal-punch-frame h2, .modal-punch-frame ion-label {
    font-weight:bold;
}

.punch-label-main .candidate {
    color:#5260ff;
} 

.modal-punch-frame .ion-page ion-footer{
    position:fixed !important;
    bottom:0 !important;
    width:94.3% !important;
}


#hardcoded_toast_confirm {
    display: none;
}

.punch-label-main {
    margin:0;
    padding:0;
}

ion-row{
    align-items: center;
  }

h6 {
    color:#777;
    margin-top:6px !important;
}